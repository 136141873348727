$.widget( "custom.cookieBar", {
    //default beállítások
    options: {
        content: 'Az oldalon történő látogatásod során cookie-kat (”sütiket”) használunk. Ezen fájlok információkat szolgáltatnak számunkra a felhasználó oldallátogatási szokásairól, de nem tárolnak személyes információkat. Az oldalon történő továbblépéssel elfogadod a cookie-k használatát.',
        descriptionLink: "http://cookie-info.index.inda.cemp.hu/",
        descriptionText: 'További információ',
        targetBlank: true,
        acceptText: "Elfogadom",
    },

    _create: function() {

        if(this._getCookie('acceptCookie') == 'accepted') {
            return false;
        }

        var content = this.options.content;
        var descriptionLink = this.options.descriptionLink;
        var descriptionText = this.options.descriptionText;
        var targetBlank = this.options.targetBlank;
        var acceptText = this.options.acceptText;
        var descriptionFullLink = "";

        if(descriptionLink !== false) {
            if(targetBlank === true) {
                var descriptionFullLink = "<a href='"+descriptionLink+"' target='_blank'>"+descriptionText+"</a>";
            } else {
                var descriptionFullLink = "<a href='"+descriptionLink+"'>"+descriptionText+"</a>";
            }
        }

        this._on(this.element, {
            'click.cookie_accept_button': function(event) {
                var $container = $(event.target);
                this._setCookie();
                this.element.addClass('inactive');
            }
        });


        var cookieContent = this._buildDom(content, descriptionFullLink, acceptText);

        this.element
            .addClass( "cookie_con" )
            .html(cookieContent);
    },

    _buildDom: function(content, descriptionFullLink, acceptText) {
        var structure = "" +
            "<div class='container'>" +
            "<div class='text'>" +
            content + " " +
            descriptionFullLink +
            "</div>" +
            "<div class='button'>" +
            "<span class='value cookie_accept_button'>" +
            acceptText +
            "</span>" +
            "<span class='ikon'>" +
            "</span>" +
            "</div>" +
            "</div>";
        return structure;
    },

    _setCookie: function() {
        var now = new Date();
        var time = now.getTime();
        time += 360000 * 10000; //így kicsit több, mint egy hónap
        now.setTime(time);
        console.log(now.toUTCString());
        document.cookie = "acceptCookie=accepted; expires=" + now.toUTCString();
    },

    _getCookie: function(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    }
});